import useSWRMutation from "swr/mutation"
import { useAPI } from "./useAPI"

const useVerifyOtp = () => {
  const { put } = useAPI()

  const verifyOtp = async (
    url: string,
    { arg }: { arg: OTPVerify }
  ) => {
    const response: any = await put(url, arg)
    return response
  }

  const { trigger, isMutating, error } = useSWRMutation(
    `/admin/auth/otp/validation`,
    verifyOtp
  )

  return {
    onVerifyOtp: trigger,
    isLoading: isMutating,
    error,
  }
}

export default useVerifyOtp
