import React, { useEffect, useState } from "react"
import styles from "./InsightDashboard.module.scss"

import BarChartView from "common/components/atoms/BarChart/BarChart"
import LineChartView from "common/components/atoms/LineChart/LineChart"
import { ReactComponent as RightIcon } from "../../common/assets/icons/right-chevron.svg"
import { ReactComponent as LeftIcon } from "../../common/assets/icons/left-chevron.svg"

import {
  startOfMonth,
  endOfMonth,
  subMonths,
  addMonths,
  format,
  isAfter,
  parseISO,
} from "date-fns"
import useTouringGraphData from "common/hooks/useTouringGraphData"
import graphStore from "store/graphStore"
import useDailyPostGraphData from "common/hooks/useDailyPostGraphData"
import useBlogPostGraphData from "common/hooks/useBlogPostGraphData"
import useTalkRoomGraphData from "common/hooks/useTalkRoomGraphData"
import Loader from "common/components/atoms/Loader"

const Dashboard = () => {
  const touringGraphData = graphStore((state) => state.touringGraphData)
  const setTouringGraphData = graphStore((state) => state.setTouringGraphData)
  const dailyPostGraphData = graphStore((state) => state.dailyPostGraphData)
  const setDailyPostGraphData = graphStore(
    (state) => state.setDailyPostGraphData
  )
  const blogPostGraphData = graphStore((state) => state.blogPostGraphData)
  const setBlogPostGraphData = graphStore((state) => state.setBlogPostGraphData)
  const talkRoomGraphData = graphStore((state) => state.talkRoomGraphData)
  const setTalkRoomGraphData = graphStore((state) => state.setTalkRoomGraphData)

  function formatJapaneseDate(isoDateString: string): string {
    const date: Date = parseISO(isoDateString)
    return format(date, "yyyy年 MM月")
  }
  interface DateRange {
    startDate: string
    endDate: string
  }
  const getInitialDateRange = (): DateRange => {
    const now = new Date()
    return {
      startDate: format(startOfMonth(now), "yyyy-MM-dd"),
      endDate: format(now, "yyyy-MM-dd"),
    }
  }

  const shiftToPreviousMonth = (currentRange: DateRange): DateRange => {
    const startDate = subMonths(new Date(currentRange.startDate), 1)
    return {
      startDate: format(startOfMonth(startDate), "yyyy-MM-dd"),
      endDate: format(endOfMonth(startDate), "yyyy-MM-dd"),
    }
  }
  const advanceToNextMonth = (currentRange: DateRange): DateRange => {
    const now = new Date()
    const startDate = addMonths(new Date(currentRange.startDate), 1)

    if (isAfter(startOfMonth(startDate), startOfMonth(now))) {
      return currentRange // Return the current range if the next month is in the future.
    }

    const endDate = endOfMonth(startDate)
    const finalEndDate = isAfter(endDate, now) ? now : endDate

    return {
      startDate: format(startOfMonth(startDate), "yyyy-MM-dd"),
      endDate: format(finalEndDate, "yyyy-MM-dd"),
    }
  }

  const [touringDateRange, setTouringDateRange] = useState(getInitialDateRange)
  const [dailyPostDateRange, setDailyPostDateRange] =
    useState(getInitialDateRange)
  const [blogPostDateRange, setBlogPostDateRange] =
    useState(getInitialDateRange)
  const [talkRoomDateRange, setTalkRoomDateRange] =
    useState(getInitialDateRange)

  const { touringGraphDetail, isLoading: touringLoading } =
    useTouringGraphData(touringDateRange)
  const { dailyPostGraphDetail, isLoading: dailyPostLoading } =
    useDailyPostGraphData(dailyPostDateRange)
  const { blogPostGraphDetail, isLoading: blogPostLoading } =
    useBlogPostGraphData(blogPostDateRange)
  const { talkRoomGraphDetail, isLoading: talkRoomLoading } =
    useTalkRoomGraphData(talkRoomDateRange)

  useEffect(() => {
    touringGraphDetail && setTouringGraphData(touringGraphDetail.result)
    dailyPostGraphDetail && setDailyPostGraphData(dailyPostGraphDetail.result)
    blogPostGraphDetail && setBlogPostGraphData(blogPostGraphDetail.result)
    talkRoomGraphDetail && setTalkRoomGraphData(talkRoomGraphDetail.result)
  })

  return (
    <div className={styles.dashboardContainer}>
      <div className={styles.topControls}>
        <div className={styles.controlButton}></div>
      </div>
      {dailyPostLoading ? (
        <div className={styles.graphContainer}>
          <h1>合計投稿数</h1>
          <Loader />
        </div>
      ) : (
        <div className={styles.graphContainer}>
          <h1>合計投稿数</h1>
          <div className={styles.dateControl}>
            <div
              onClick={() => setDailyPostDateRange(shiftToPreviousMonth)}
              className={styles.control}
            >
              前の月 <LeftIcon />
            </div>
            <span>{formatJapaneseDate(dailyPostDateRange.startDate)}</span>
            <div
              onClick={() => setDailyPostDateRange(advanceToNextMonth)}
              className={styles.control}
            >
              <RightIcon />
              次の月
            </div>
          </div>
          <div className={styles.graphArea}>
            {dailyPostGraphData?.length ? (
              <BarChartView data={dailyPostGraphData} />
            ) : (
              <h1>-</h1>
            )}
          </div>
        </div>
      )}

      {blogPostLoading ? (
        <div className={styles.graphContainer}>
          <h1>合計整備日記数</h1>
          <Loader />
        </div>
      ) : (
        <div className={styles.graphContainer}>
          <h1>合計整備日記数</h1>
          <div className={styles.dateControl}>
            <div
              onClick={() => setBlogPostDateRange(shiftToPreviousMonth)}
              className={styles.control}
            >
              前の月 <LeftIcon />
            </div>
            <span>{formatJapaneseDate(blogPostDateRange.startDate)}</span>
            <div
              onClick={() => setBlogPostDateRange(advanceToNextMonth)}
              className={styles.control}
            >
              <RightIcon />
              次の月
            </div>
          </div>
          <div className={styles.graphArea}>
            {blogPostGraphData?.length ? (
              <BarChartView data={blogPostGraphData} />
            ) : (
              <h1>-</h1>
            )}
          </div>
        </div>
      )}

      {talkRoomLoading ? (
        <div className={styles.graphContainer}>
          <h1>合計トークルーム数</h1>
          <Loader />
        </div>
      ) : (
        <div className={styles.graphContainer}>
          <h1>合計トークルーム数</h1>
          <div className={styles.dateControl}>
            <div
              onClick={() => setTalkRoomDateRange(shiftToPreviousMonth)}
              className={styles.control}
            >
              前の月 <LeftIcon />
            </div>
            <span>{formatJapaneseDate(talkRoomDateRange.startDate)}</span>
            <div
              onClick={() => setTalkRoomDateRange(advanceToNextMonth)}
              className={styles.control}
            >
              <RightIcon />
              次の月
            </div>
          </div>
          <div className={styles.graphArea}>
            {talkRoomGraphData?.length ? (
              <BarChartView data={talkRoomGraphData} />
            ) : (
              <h1>-</h1>
            )}
          </div>
        </div>
      )}

      {touringLoading ? (
        <div className={styles.graphContainer}>
          <h1>合計ツーリング数</h1>
          <Loader />
        </div>
      ) : (
        <div className={styles.chartContainer}>
          <h1>合計ツーリング数</h1>
          <div className={styles.dateControl}>
            <div
              onClick={() => setTouringDateRange(shiftToPreviousMonth)}
              className={styles.control}
            >
              前の月 <LeftIcon />
            </div>
            <span>{formatJapaneseDate(touringDateRange.startDate)}</span>
            <div
              onClick={() => setTouringDateRange(advanceToNextMonth)}
              className={styles.control}
            >
              <RightIcon />
              次の月
            </div>
          </div>
          <div className={styles.chartArea}>
            {touringGraphData?.length ? (
              <LineChartView data={touringGraphData} />
            ) : (
              <h1>-</h1>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Dashboard
