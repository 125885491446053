import React, { useEffect, useState } from "react"
import Title from "common/components/atoms/Title"
import Button from "common/components/atoms/Button"
import Link from "common/components/atoms/Link"
import FormLayout from "common/components/templates/FormLayout"
import * as yup from "yup"
import { useNavigate } from "react-router-dom"
import Loader from "common/components/atoms/Loader"
import { useTranslation } from "react-i18next"
import useVerifyOtp from "common/hooks/useVerifyOtp"
import OtpInput from "common/components/atoms/OtpInput/OtpInput"
import otpStore from "store/otpStore"

const OTP = () => {
  const navigate = useNavigate()
  const setOtpId = otpStore((state) => state.setOtpId)
  const { t } = useTranslation()
  const [otpInput, setOtpInput] = useState<string[]>(new Array(6).fill(""))
  const [otp, setOtp] = useState("")
  const [error, setError] = useState("")

  const { onVerifyOtp, isLoading } = useVerifyOtp()

  const verifyOtp = () => {
    if (otp.length < 6) {
      setError("Enter All Digit")
    }
    onVerifyOtp({ otp: otp })
      .then((response) => {
        if (response.success) {
          setOtpId(response.otpId)
          navigate("/reset-password")
        }
      })
      .catch((error: any) => setError("Wrong otp"))
  }
  useEffect(() => {
    setOtp(otpInput.join(""))
  }, [otpInput])

  return (
    <FormLayout>
      <Title order={5} color="black" fw="bold">
        ワンタイムパスワードの認証
      </Title>
      <div
        style={{
          width: "100%",
          padding: "2rem",
          gap: "24px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <OtpInput otp={otpInput} setOtp={setOtpInput} />
        {error && <span style={{ textAlign: "left" }}>{error}</span>}
        <Button variant="black" onClick={verifyOtp}>
          {isLoading && <Loader />} {t("buttons.sendEmail")}
        </Button>
      </div>
    </FormLayout>
  )
}

export default OTP
