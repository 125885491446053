import Button from "common/components/atoms/Button"
import Text from "common/components/atoms/Text"
import React from "react"

const Test = () => {
  return (
    <div
      style={{
        backgroundColor: "grey",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Text sz="lg" fw="bold" ta="center">
        Buttons
      </Text>
      <div style={{ maxWidth: 400, width: "100%" }}>
        <Button>絞り込む</Button>
        <Button variant="grey">絞り込む</Button>
      </div>
      <Text sz="lg" fw="bold" ta="center">
        Routes
      </Text>
      <ul style={{ textAlign: "left" }}>
        <li>
          <a href="/login">login</a>
        </li>
        <li>
          <a href="/forget-password">Forgot Password</a>
        </li>
        <li>
          <a href="/password-sent">Password Sent</a>
        </li>
        <li>
          <a href="/reset-password">Reset Password</a>
        </li>
        <li>
          <a href="/user-list">Dashboard</a>
        </li>
      </ul>
    </div>
  )
}

export default Test
