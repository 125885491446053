import useSWR from "swr"
import { useAPI } from "./useAPI"

interface BikeListResponse {
  success: true
  result: {
    docs: BikeListData[]
    totalDocs: number
    limit: number
    page: number
    totalPages: number
    pagingCounter: number
    hasPrevPage: boolean
    hasNextPage: boolean
    offset: number
    prevPage: number
    nextPage: number
  }
}

const useBikeList = (nextOffset: number = 0, searchKey: string = "") => {
  const { get } = useAPI()
  const {
    data: bikeDetails,
    error,
    mutate: fetchBikeList,
    isLoading,
  } = useSWR<BikeListResponse>(
    `/admin/bikeModel?offset=${nextOffset || 0}&limit=50&search=${searchKey}`,
    (url: string) => get(url),
    {
      revalidateOnFocus: false,
    }
  )

  return {
    bikeDetails,
    fetchBikeList,
    isLoading,
    error,
  }
}

export default useBikeList