import React, { useState } from "react"
import Button from "common/components/atoms/Button"
import Card from "common/components/atoms/Card"
import Input from "common/components/atoms/Input"
import Modal from "common/components/atoms/Modal"
import Title from "common/components/atoms/Title"
import useAddBike from "common/hooks/useAddBike"
import Loader from "common/components/atoms/Loader"
import useError from "common/hooks/useErrors"
import { getErrorMessage } from "utils/helper"
import ConfirmAddBikeModal from "../ConfirmAddBikeModal/ConfirmAddBikeModal"

const AddBikeModal = ({
  isOpen,
  onClose,
  bikeModel,
  setBikeModel,
}: {
  isOpen: boolean
  onClose: () => void
  bikeModel: AddBikePayload
  setBikeModel: React.Dispatch<React.SetStateAction<AddBikePayload>>
}) => {
  const { onAddBike, isLoading } = useAddBike()
  const setErrorNotification = useError((state) => state.setError)
  const [isConfirmOpen, setConfirmOpen] = useState(false)
  const [error, setError] = useState("")

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setBikeModel((prev) => ({ ...prev, [name]: value }))
  }

  const handleSubmit = () => {
    if (!bikeModel.name || !bikeModel.brand || !bikeModel.emission) {
      setError("All fields are required")
      return
    }
    setError("")
    onAddBike(bikeModel)
      .then((res) => {
        res.success && onClose()
        setErrorNotification("Bike added successfully", "success")
      })
      .catch((err) => {
        setErrorNotification(getErrorMessage(err.subStatusCode))
      })
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <Card>
          <Title order={6} fw="bold">
            バイクを追加
          </Title>
          <div
            style={{
              width: "100%",
              padding: "2rem",
              gap: "24px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Input
              placeholder="バイク名を入力してください"
              label="バイク名"
              name="name"
              value={bikeModel.name}
              onChange={handleInputChange}
              id=""
            />
            <Input
              placeholder="メーカーを入力してください"
              label="メーカー"
              name="brand"
              value={bikeModel.brand}
              onChange={handleInputChange}
              id=""
            />
            <Input
              placeholder="排気量を入力してください"
              label="排気量"
              name="emission"
              value={bikeModel.emission}
              onChange={handleInputChange}
              id=""
            />
            {error && <div style={{ color: "red" }}>{error}</div>}

            <div style={{ width: "100%", marginTop: "20px" }}>
              <Button
                onClick={() => {
                  setConfirmOpen(true)
                }}
              >
                {isLoading && <Loader />}
                次へ
              </Button>
              <Button onClick={onClose} variant="grey">
                キャンセル
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
      <ConfirmAddBikeModal
        isOpen={isConfirmOpen}
        onClose={() => {
          setConfirmOpen(false)
        }}
        bikeModel={bikeModel}
        closeEditmodal={onClose}
      />
    </>
  )
}

export default AddBikeModal
