import axios, { isAxiosError } from "axios";

export const refreshAccessToken = async (
  payload: RefreshTokenPayload,
  userType: string
) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/admin/auth/refresh`,
      payload
    );
    return Promise.resolve(response.data);
  } catch (err: unknown) {
    if (isAxiosError(err)) {
      return Promise.reject(err.response?.data);
    } else return Promise.reject(err);
  }
};
