import { create } from "zustand"

interface FilterState {
  keyword: string
  setKeyword: (data: string) => void
}

const filterStore = create<FilterState>((set) => ({
  keyword: "",
  setKeyword: (data) => set({ keyword: data }),
}))

export default filterStore
