import Button from "common/components/atoms/Button"
import Card from "common/components/atoms/Card"
import Dropdown from "common/components/atoms/Dropdown"
import Input from "common/components/atoms/Input"
import Modal from "common/components/atoms/Modal"
import Title from "common/components/atoms/Title"
import useUserList from "common/hooks/useUserList"
import React, { useEffect, useState } from "react"
import filterStore from "store/filterStore"
import userStore from "store/userStore"

const PrefecturesFilterModal = ({
  isOpen,
  onClose,

}: {
  isOpen: boolean
  onClose: () => void

}) => {
  const userType = [
    { value: "", label: "選択してください" },
    { value: "true", label: "法人" },
    { value: "false", label: "個人" },
  ]
  const [searchKeyword, setSearchKeyword] = useState("")
  const keyword = filterStore((state) => state.keyword)
  const setKeyword = filterStore((state) => state.setKeyword)
  const { userDetails, error, isLoading } = useUserList(0, keyword)
  const setUser = userStore((state) => state.setUser)

  useEffect(() => {
    if (userDetails) {
      setUser(userDetails.result.docs)
    }
  }, [setUser, userDetails])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Card>
        <Title order={6} fw="bold">
          絞り込み検索
        </Title>
        <div
          style={{
            width: "100%",
            padding: "2rem",
            gap: "24px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Input
            label={"検索する"}
            id={""}
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
          <div style={{ width: "100%" }}>
            <Button
              onClick={() => {
                setKeyword(searchKeyword)
                onClose()
              }}
            >
              絞り込む
            </Button>
            <Button
              variant="grey"
              onClick={() => {
                setKeyword("")
                onClose()
              }}
            >
              絞り込みをクリア
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  )
}

export default PrefecturesFilterModal
