import React, { useEffect, useState } from "react"
import Button from "common/components/atoms/Button"
import Card from "common/components/atoms/Card"
import Input from "common/components/atoms/Input"
import Title from "common/components/atoms/Title"
import Modal from "common/components/atoms/Modal"
import useAdmin from "common/hooks/useAdmin"
import useUpdateAdmin from "common/hooks/useUpdateAdmin"
import useError from "common/hooks/useErrors"
import Loader from "common/components/atoms/Loader"

const EditAccountModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const { adminDetails } = useAdmin()
  const [email, setEmail] = useState("")
  const { onUpdateEmail,isLoading } = useUpdateAdmin()
  const setErrorNotification = useError((state) => state.setError)

  useEffect(() => {
    if (adminDetails) {
      setEmail(adminDetails.result)
    }
  }, [adminDetails])

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Card>
        <Title order={6} fw="bold">
          アカウントを編集
        </Title>
        <div
          style={{
            width: "100%",
            padding: "2rem",
            gap: "24px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Input
            placeholder="メールアドレスを入力してください"
            label={"メールアドレス"}
            id={""}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div style={{ width: "100%" }}>
            <Button
              onClick={() =>
                onUpdateEmail({ email: email })
                  .then(
                    (res) =>
                      res.success &&
                      setErrorNotification("Updated Successfully", "success")
                  )
                  .catch((err) => setErrorNotification("Something went wrong"))
              }
            >
              {isLoading && <Loader />}
              編集する
            </Button>
            <Button variant="grey" onClick={onClose}>
              キャンセル
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  )
}

export default EditAccountModal
