import React from "react"
import SideBar from "common/components/SideBar"
import AppShell from "common/components/atoms/Appshell"
import Navbar from "common/components/atoms/Navbar"
import { Navigate } from "react-router-dom"
import { getCookies } from "utils/cookies"
import useAuthStore from "store/authStore"

interface ProtectedRoutesProps {
  children: JSX.Element
}

const ProtectedRoutes = ({ children }: ProtectedRoutesProps) => {
  const isAuthenticated = getCookies("accessToken")
  // const isAuthenticated = true

  // const userType = useAuthStore((state) => state.userType)

  return isAuthenticated ? (
    <AppShell SidebarComponent={<SideBar />} NavbarComponent={<Navbar />}>
      {children}
    </AppShell>
  ) : (
    <Navigate to="/login" />
  )
}

export default ProtectedRoutes
