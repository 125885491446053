import { create } from "zustand"

interface GraphState {
  touringGraphData: TouringGraphData[] | null
  setTouringGraphData: (data: TouringGraphData[]) => void
  barGraphData: BarGraphData[] | null
  setBarGraphData: (data: BarGraphData[]) => void
  dailyPostGraphData: BarGraphData[] | null
  setDailyPostGraphData: (data: BarGraphData[]) => void
  blogPostGraphData: BarGraphData[] | null
  setBlogPostGraphData: (data: BarGraphData[]) => void
  talkRoomGraphData: BarGraphData[] | null
  setTalkRoomGraphData: (data: BarGraphData[]) => void
}

const graphStore = create<GraphState>((set) => ({
  touringGraphData: null,
  setTouringGraphData: (data) => set({ touringGraphData: data }),
  barGraphData: null,
  setBarGraphData: (data) => set({ barGraphData: data }),
  dailyPostGraphData: null,
  setDailyPostGraphData: (data) => set({ dailyPostGraphData: data }),
  blogPostGraphData: null,
  setBlogPostGraphData: (data) => set({ blogPostGraphData: data }),
  talkRoomGraphData: null,
  setTalkRoomGraphData: (data) => set({ talkRoomGraphData: data }),
}))

export default graphStore
