import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"

const LineChartView = ({ data }: any) => (
  <>
    {data && (
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={700}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="_id" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="count" name="数" stroke="#8884d8" />
        </LineChart>
      </ResponsiveContainer>
    )}
  </>
)

export default LineChartView
