import useSWR from "swr"
import { useAPI } from "./useAPI"

interface GraphDataResponse {
  success: true
  result: TouringGraphData[]
}

const useTouringGraphData = ({
  startDate,
  endDate,
}: {
  startDate: string
  endDate: string
}) => {
  const { get } = useAPI()
  const {
    data: touringGraphDetail,
    error,
    mutate: fetchTouringGraphDetails,
    isLoading,
  } = useSWR<GraphDataResponse>(
    `/admin/category/touringCount?startDate=${startDate}&endDate=${endDate}`,
    (url: string) => get(url),
    {
      revalidateOnFocus: false,
    }
  )

  return {
    touringGraphDetail,
    fetchTouringGraphDetails,
    isLoading,
    error,
  }
}

export default useTouringGraphData
