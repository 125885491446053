import React, { useState } from "react"
import styles from "./OtpInput.module.scss"

interface OtpInputProps {
  otp: string[]
  setOtp: any
}

const OtpInput: React.FC<OtpInputProps> = ({ otp, setOtp }) => {
  // const [otp, setOtp] = useState<string[]>(new Array(length).fill(""));

  const handleChange = (
    element: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = element.target.value
    const newOtp = [...otp]

    // Only update if the new input is empty (backspace was hit) or a single digit
    if (!value || /^[0-9]$/.test(value)) {
      newOtp[index] = value
      setOtp(newOtp)

      // Automatically focus the next input if a digit is entered
      if (value && element.target.nextSibling) {
        ;(element.target.nextSibling as HTMLInputElement).focus()
      }
    }
  }

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.key === "Backspace") {
      if (otp[index] === "") {
        event.preventDefault() // Stop the input from losing focus
        // Move focus to previous input if current is empty
        if (index > 0) {
          const prevSibling = (event.target as HTMLInputElement)
            .previousSibling as HTMLInputElement | null
          if (prevSibling) {
            prevSibling.focus()
            const newOtp = [...otp]
            newOtp[index - 1] = "" // Clear the previous field
            setOtp(newOtp)
          }
        }
      } else {
        // Clear the current field
        const newOtp = [...otp]
        newOtp[index] = ""
        setOtp(newOtp)
      }
    }
  }

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault()
    const pasteData = e.clipboardData.getData("text").slice(0, 6).split("")
    if (pasteData.every((char) => /^[0-9]$/.test(char))) {
      setOtp(pasteData)
      const lastInput = document.querySelectorAll(`input[type="text"]`)[
        pasteData.length - 1
      ] as HTMLInputElement | null
      if (lastInput) {
        lastInput.focus()
      }
    }
  }

  return (
    <div onPaste={handlePaste} className={styles.otpInput}>
      {otp.map((data, index) => (
        <input
          key={index}
          type="text" // Suitable for single character handling
          maxLength={1}
          value={data}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          style={{
            width: "50px",
            height: "50px",
            marginRight: "5px",
            textAlign: "center",
            borderRadius: "6px",
            border: "1px solid #000000",
            fontSize: "20px",
          }}
        />
      ))}
    </div>
  )
}

export default OtpInput
