import { create } from "zustand"

interface UserState {
  user: UserProfileData[] | null
  setUser: (data: UserProfileData[]) => void
  userDetail: UserProfileData | null
  setUserDetail: (data: UserProfileData) => void
}

const userStore = create<UserState>((set) => ({
  user: null,
  setUser: (data) => set({ user: data }),
  userDetail: null,
  setUserDetail: (data) => set({ userDetail: data }),
}))

export default userStore