import useSWR from "swr"
import { useAPI } from "./useAPI"

interface UserDetailsResponse {
  success: true
  result: UserProfileData[]
}

const useAdmin = () => {
  const { get } = useAPI()
  const {
    data: adminDetails,
    error,
    mutate: fetchUserDetails,
    isLoading,
  } = useSWR<any>(`/admin/profile`, (url: string) => get(url), {
    revalidateOnFocus: false,
  })

  return {
    adminDetails,
    fetchUserDetails,
    isLoading,
    error,
  }
}

export default useAdmin
