import React, { ReactNode } from 'react'
import './Breadcrumb.scss'
import { Link } from 'react-router-dom'

interface BreadcrumbItem {
  text: string
  url?: string
}

interface BreadcrumbProps {
  items: BreadcrumbItem[]
  separator: string | ReactNode
}

function Breadcrumb({ items, separator }: BreadcrumbProps) {
  return (
    <nav aria-label="Breadcrumb">
      <ol className="breadcrumb">
        {items.map((item, index) => (
          <li key={index} className="breadcrumb-item">
            {index === items.length - 1 ? (
              <span className="current">{item.text}</span>
            ) : (
              <>
                {item.url ? (
                  <Link to={item.url}>{item.text}</Link>
                ) : (
                  <span>{item.text}</span>
                )}
                <span className="separator">{separator}</span>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default Breadcrumb
