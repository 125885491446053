import React, { useEffect, useState } from "react"
import Button from "common/components/atoms/Button"
import { createColumnHelper } from "@tanstack/react-table"
import Table from "common/components/atoms/Table"
import PlusIcon from "../../common/assets/icons/plus.svg"
import { ReactComponent as EditIcon } from "../../common/assets/icons/edit.svg"

import { useLocation, useNavigate } from "react-router-dom"
import useBikeList from "common/hooks/useBikeList"
import bikeStore from "store/bikeStore"
import filterStore from "store/filterStore"
import BikeFilterModal from "common/components/templates/BikeFilterModal/BikeFilterModal"
import Loader from "common/components/atoms/Loader"
import AddBikeModal from "common/components/templates/AddBikeModal/AddBikeModal"

const Dashboard = () => {
  const columnHelper = createColumnHelper<BikeData>()
  const [nextOffset, setNextOffset] = useState(0)
  const [isFilterModalOpen, setFilterModalOpen] = useState(false)
  const [isAddModalOpen, setAddModalOpen] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState("")
  const location = useLocation()
  const navigate = useNavigate()
  const keyword = filterStore((state) => state.keyword)
  const { bikeDetails, error, isLoading } = useBikeList(nextOffset)
  const [bikeModel, setBikeModel] = useState<AddBikePayload>({
    name: "",
    brand: "",
    emission: "",
  })

  const bikeList = bikeStore((state) => state.bikeList)
  const setBikeList = bikeStore((state) => state.setBikeList)

  useEffect(() => {
    if (bikeDetails) {
      setBikeList(bikeDetails.result.docs)
    }
  }, [setBikeList, bikeDetails])

  const onLoadData = (direction: "NEXT" | "PREV" = "NEXT") => {

    const limit = bikeDetails?.result.limit || 10
    const offset =
      direction === "NEXT"
        ? ((bikeDetails?.result.nextPage ?? 1) - 1) * limit
        : ((bikeDetails?.result.prevPage ?? 1) - 1) * limit
    setNextOffset(offset)
  }

  const columns = [
    columnHelper.accessor("name", {
      header: () => "バイク名",
      cell: (info) => `${info.row.original.name}`,
    }),
    columnHelper.accessor("brand", {
      header: () => "メーカー",
      cell: (info) => `${info.row.original.brand}`,
    }),
    columnHelper.accessor("emission", {
      header: () => "排気量",
      cell: (info) => `${info.row.original.emission}`,
    }),
    columnHelper.accessor("noUserBikeCount", {
      header: () => "ガレージ登録者数",
      cell: (info) => `${info.row.original.noUserBikeCount}`,
    }),

    columnHelper.accessor("_id", {
      header: () => "",
      cell: (info) => (
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            color: "green",
            cursor: "pointer",
          }}
          onClick={() => {
            setAddModalOpen(true)
            setBikeModel({
              name: info.row.original.name,
              brand: info.row.original.brand,
              emission: info.row.original.emission,
            })
          }}
        >
          <EditIcon width={20} height={20} />
        </div>
      ),
    }),
  ]

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        padding: "1.56rem",
      }}
    >
      <div
        style={{
          padding: "1.56rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ maxWidth: "17.25rem", width: "100%" }}>
          <Button
            onClick={() => setAddModalOpen(true)}
            icon={PlusIcon}
            iconPosition="left"
            variant="action"
          >
            バイクの追加登録
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            gap: "1.5rem",
            alignItems: "center",
          }}
        >
          <p>登録バイク数</p>
          {isLoading ? (
            <Loader />
          ) : (
            <p style={{ fontWeight: 700, fontSize: 24 }}>
              {bikeDetails?.result.totalDocs}
            </p>
          )}
        </div>
      </div>
      {isLoading && <Loader sz="lg" />}
      {bikeList && (
        <Table
          onFilter={() => {
            setFilterModalOpen(true)
          }}
          tableTitle="バイクリスト"
          data={bikeList}
          columns={columns}
          onNext={
            bikeDetails?.result.hasNextPage
              ? () => onLoadData("NEXT")
              : undefined
          }
          onPrev={
            bikeDetails?.result.hasPrevPage
              ? () => onLoadData("PREV")
              : undefined
          }
          isLoading={isLoading}
        />
      )}
      <AddBikeModal
        isOpen={isAddModalOpen}
        onClose={() => {
          setBikeModel({
            name: "",
            brand: "",
            emission: "",
          })
          setAddModalOpen(false)
        }}
        bikeModel={bikeModel}
        setBikeModel={setBikeModel}
      />
      <BikeFilterModal
        isOpen={isFilterModalOpen}
        onClose={() => {
          setFilterModalOpen(false)
        }}
      />
    </div>
  )
}

export default Dashboard
