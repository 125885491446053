import { create } from "zustand"

interface BikeState {
  bikeList: BikeListData[] | null
  setBikeList: (data: BikeListData[]) => void
}

const bikeStore = create<BikeState>((set) => ({
  bikeList: null,
  setBikeList: (data) => set({ bikeList: data }),
}))

export default bikeStore
