import React from "react"
import Title from "common/components/atoms/Title"
import Input from "common/components/atoms/Input"
import Button from "common/components/atoms/Button"
import Link from "common/components/atoms/Link"
import FormLayout from "common/components/templates/FormLayout"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { emailRegex } from "utils/constant"
import { useNavigate } from "react-router-dom"
import useForgotPassword from "common/hooks/useForgotPassword"
import useError from "common/hooks/useErrors"
import { getErrorMessage } from "utils/helper"
import Loader from "common/components/atoms/Loader"
import { useTranslation } from "react-i18next"
import useAuthStore from "store/authStore"

const ForgetPassword = () => {
  const navigate = useNavigate()
  const setError = useError((state) => state.setError)
  const { t } = useTranslation()
  const locale = useAuthStore((state) => state.locale)

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(getErrorMessage("1006"))
      .required(getErrorMessage("1005"))
      .matches(emailRegex, getErrorMessage("1006")),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const { onForgotPassword, isLoading } = useForgotPassword()

  const onSendEmail = ({ email }: ForgotPasswordPayload) => {
    onForgotPassword({ email: email, locale: locale })
      .then((response) => {
        if (response.success) {
          navigate("/otp-verify")
        }
      })
      .catch((error: any) => setError(getErrorMessage(error.subStatusCode)))
  }

  return (
    <FormLayout>
      <Title order={5} color="black" fw="bold">
        {t("titles.forgotPassword")}
      </Title>
      <div
        style={{
          width: "100%",
          padding: "2rem",
          gap: "24px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Input
          label={t("fields.newPassword")}
          placeholder={t("placeholders.newPassword")}
          id={"email"}
          inputProps={{ ...register("email") }}
          type="email"
          error={errors.email?.message}
        />
        <Button variant="black" onClick={handleSubmit(onSendEmail)}>
          {isLoading && <Loader />} {t("buttons.sendEmail")}
        </Button>
        <Link to="/login">{t("links.backToLogin")}</Link>
      </div>
    </FormLayout>
  )
}

export default ForgetPassword
