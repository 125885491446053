import useSWR from "swr"
import { useAPI } from "./useAPI"

interface UserDetailsResponse {
  success: true
  result: UserProfileData[]
}

const useUserDetails = (userId?: string) => {
  const { get } = useAPI()
  const {
    data: userDetails,
    error,
    mutate: fetchUserDetails,
    isLoading,
  } = useSWR<UserDetailsResponse>(
    `/admin/users/${userId}/getOne`,
    (url: string) => get(url),
    {
      revalidateOnFocus: false,
    }
  )

  return {
    userDetails,
    fetchUserDetails,
    isLoading,
    error,
  }
}

export default useUserDetails
