import React, { useEffect, useState } from "react"
import { createColumnHelper } from "@tanstack/react-table"
import Table from "common/components/atoms/Table"
import { ReactComponent as RightArrow } from "../../common/assets/icons/right-arrow.svg"

import { useNavigate } from "react-router-dom"
import userStore from "store/userStore"
import useUserList from "common/hooks/useUserList"
import PrefecturesFilterModal from "common/components/templates/PrefecturesFilterModal/PrefecturesFilterModal"
import filterStore from "store/filterStore"
import Loader from "common/components/atoms/Loader"
import useError from "common/hooks/useErrors"
import { formatDate } from "utils/helper"

const Dashboard = () => {
  const columnHelper = createColumnHelper<UserProfileData>()
  const [isFilterModalOpen, setFilterModalOpen] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState("")
  const navigate = useNavigate()
  const [nextOffset, setNextOffset] = useState(0)
  const keyword = filterStore((state) => state.keyword)
  const { userDetails, error, isLoading } = useUserList(nextOffset, keyword)
  const setUser = userStore((state) => state.setUser)
  const usersList = userStore((state) => state.user)

  useEffect(() => {
    if (userDetails) {
      setUser(userDetails.result.docs)
    }
  }, [setUser, userDetails])

  const onLoadData = (direction: "NEXT" | "PREV" = "NEXT") => {
    const limit = userDetails?.result.limit || 10
    const offset =
      direction === "NEXT"
        ? ((userDetails?.result.nextPage ?? 1) - 1) * limit
        : ((userDetails?.result.prevPage ?? 1) - 1) * limit
    setNextOffset(offset)
  }

  const columns = [
    columnHelper.accessor("nickName", {
      header: () => "ニックネーム",
      cell: (info) => `${info.row.original.nickName}`,
    }),
    columnHelper.accessor("province", {
      header: () => "都道府県",
      cell: (info) => `${info.row.original.province}`,
    }),
    columnHelper.accessor("gender", {
      header: () => "性別",
      cell: (info) => {
        if (info.row.original.gender === "Male") {
          return "男性"
        } else {
          return "女性"
        }
      },
    }),
    columnHelper.accessor("followersCount", {
      header: () => "フォロー数",
      cell: (info) => `${info.row.original.followersCount}`,
    }),
    columnHelper.accessor("followingCount", {
      header: () => "フォロワー数",
      cell: (info) => `${info.row.original.followingCount}`,
    }),
    columnHelper.accessor("lastLogin", {
      header: () => "最終ログイン",
      cell: (info) =>
        `${
          info.row.original.lastLogin !== undefined
            ? formatDate(info.row.original.lastLogin)
            : "-"
        }`,
    }),
    columnHelper.accessor("bikeCount", {
      header: () => "登録バイク数",
      cell: (info) => `${info.row.original.bikeCount}`,
    }),
    columnHelper.accessor("_id", {
      header: () => "",
      cell: (info) => (
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            color: "green",
            cursor: "pointer",
          }}
          onClick={() => navigate(`/user-list/${info.row.original.userId}`)}
        >
          <RightArrow />
        </div>
      ),
    }),
  ]

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
        height: "90vh",
        overflow: "auto",
        width: "100%",
        padding: "1.56rem",
      }}
    >
      <div
        style={{
          padding: "1.56rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div></div>
        <div
          style={{
            display: "flex",
            gap: "1.5rem",
            alignItems: "center",
          }}
        >
          <p>登録ユーザー数</p>
          {isLoading ? (
            <Loader />
          ) : (
            <p style={{ fontWeight: 700, fontSize: 24 }}>
              {userDetails?.result.totalDocs}
            </p>
          )}
        </div>
      </div>
      {isLoading && <Loader sz="lg" />}
      {usersList && (
        <Table
          onFilter={() => {
            setFilterModalOpen(true)
          }}
          tableTitle="ユーザーリスト"
          data={usersList}
          columns={columns}
          onNext={
            userDetails?.result.hasNextPage
              ? () => onLoadData("NEXT")
              : undefined
          }
          onPrev={
            userDetails?.result.hasPrevPage
              ? () => onLoadData("PREV")
              : undefined
          }
          isLoading={isLoading}
          // totalPage={userDetails?.result.totalDocs / 50}
        />
      )}
      <PrefecturesFilterModal
        isOpen={isFilterModalOpen}
        onClose={() => setFilterModalOpen(false)}
      />
    </div>
  )
}

export default Dashboard
