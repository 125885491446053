import React from "react"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"


const BarChartView = ({ data }: any) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={700}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 20,
        }}
        barSize={37}
        innerRadius={10}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="categoryName" />
        <YAxis dataKey="count" />
        <Tooltip />
        <Legend />
        <Bar dataKey="count" stackId="a" name="数" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  )
}

export default BarChartView
