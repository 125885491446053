import { create } from "zustand"

interface OtpState {
  otpId: string | null
  setOtpId: (data: string) => void
}

const otpStore = create<OtpState>((set) => ({
  otpId: null,
  setOtpId: (data) => set({ otpId: data }),
}))

export default otpStore
