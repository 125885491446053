import useSWRMutation from "swr/mutation"
import { useAPI } from "./useAPI"

const useAddBike = () => {
  const { post } = useAPI()

  const addBike = async (url: string, { arg }: { arg: AddBikePayload }) => {
    const response: { success: true } = await post(url, arg)
    return response
  }

  const { trigger, isMutating, error } = useSWRMutation(
    `/admin/bikeModel`,
    addBike
  )

  return {
    onAddBike: trigger,
    isLoading: isMutating,
    error,
  }
}

export default useAddBike
