import useSWR from "swr"
import { useAPI } from "./useAPI"

interface UserDetailsResponse {
  success: true
  result: {
    docs: UserProfileData[]
    totalDocs: number
    limit: number
    page: number
    totalPages: number
    pagingCounter: number
    hasPrevPage: boolean
    hasNextPage: boolean
    offset: number
    prevPage: number
    nextPage: number
  }
}

const useUserList = (nextOffset: number = 0, searchKey: string = "") => {
  const { get } = useAPI()
  const {
    data: userDetails,
    error,
    mutate: fetchUserDetails,
    isLoading,
  } = useSWR<UserDetailsResponse>(
    `/admin/users?offset=${nextOffset || 0}&limit=50&search=${searchKey}`,
    (url: string) => get(url),
    {
      revalidateOnFocus: false,
    }
  )

  return {
    userDetails,
    fetchUserDetails,
    isLoading,
    error,
  }
}

export default useUserList

