import useSWRMutation from "swr/mutation"
import { useAPI } from "./useAPI"

const useUpdateAdmin = () => {
  const { put } = useAPI()

  const updateEmail = async (
    url: string,
    { arg }: { arg: EmailEdit }
  ) => {
    const response: { success: true } = await put(url, arg)
    return response
  }

  const { trigger, isMutating, error } = useSWRMutation(
    `/admin/profile/update`,
    updateEmail
  )

  return {
    onUpdateEmail: trigger,
    isLoading: isMutating,
    error,
  }
}

export default useUpdateAdmin
