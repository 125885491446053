import React from 'react'
import { useParams } from "react-router-dom"

export const BikeDetails = () => {
  const params = useParams()
  const id = params?.id
  return (
    <div>BikeDetails {id}</div>
  )
}
