import useSWRMutation from "swr/mutation"
import { useAPI } from "./useAPI"

const useDeactivateUser = (userId?: string) => {
  const { put } = useAPI()

  const deactivateAccount = async (url: string) => {
    const response: { success: true } = await put(url)
    return response
  }

  const { trigger, isMutating, error } = useSWRMutation(
    `/admin/users/${userId}/deactivated`,
    deactivateAccount
  )

  return {
    onDeactivate: trigger,
    isLoading: isMutating,
    error,
  }
}

export default useDeactivateUser
