import Button from "common/components/atoms/Button"
import Card from "common/components/atoms/Card"
import Title from "common/components/atoms/Title"
import Text from "common/components/atoms/Text"
import React from "react"
import Modal from "common/components/atoms/Modal"
import useDeactivateUser from "common/hooks/useDeactivateUser"
import Loader from "common/components/atoms/Loader"

const DeactivateUserModal = ({
  isOpen,
  onClose,
  userId,
  name,
  title,
  fetchUserDetails
}: {
  isOpen: boolean
  onClose: () => void
  userId: string
  name: string
  title: string
  fetchUserDetails: () => void
}) => {
  const { onDeactivate, isLoading } = useDeactivateUser(userId)
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Card>
        <Title order={6} fw="bold">
          {title}
        </Title>
        <div style={{ width: "100%", padding: "2rem" }}>
          <div style={{ width: "100%" }}>
            <Text ta="left" sz="xxs">
              ニックネーム
            </Text>
            <Text ta="left" fw="bold" sz="sm">
              {name}
            </Text>
          </div>
          <div style={{ width: "100%" }}>
            <Button
              onClick={() =>
                onDeactivate().then((res) => {
                  res.success && onClose()
                  fetchUserDetails()
                })
              }
            >
              {isLoading && <Loader />} 停止する
            </Button>
            <Button variant="grey" onClick={onClose}>
              キャンセル
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  )
}

export default DeactivateUserModal
