import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styles from "./AccountDetails.module.scss"
import Button from "common/components/atoms/Button"
import CancelIcon from "../../../assets/icons/cancel.svg"
import ActivateIcon from "../../../assets/icons/activate.svg"

import DeactivateUserModal from "../DeactivateUserModal/DeactivateUserModal"
import useUserDetails from "common/hooks/useUserDetails"
import Loader from "common/components/atoms/Loader"
import userStore from "store/userStore"

const AccountDetails = () => {
  const params = useParams()
  const [isDeactivateModalOpen, setDeactivateModalOpen] = useState(false)
  const setUserDetail = userStore((state) => state.setUserDetail)

  const id = params?.id
  const { userDetails, fetchUserDetails, isLoading, error } = useUserDetails(id)

  useEffect(() => {
    if (userDetails) {
      setUserDetail(userDetails.result[0])
    }
  }, [userDetails])

  const BikeDetail = ({
    imageUrl,
    bikeBrand,
    bikeModel,
    engineCapacity,
  }: {
    imageUrl: string
    bikeBrand: string
    bikeModel: string
    engineCapacity: string
  }) => {
    return (
      <div className={styles.bikeDetail}>
        <img
          src={`https://d2r3f355job4cx.cloudfront.net/${imageUrl}`}
          alt={`${bikeModel}`}
          style={{
            height: "100px",
            width: "100px",
          }}
          className={styles.bikeImage}
        />
        <div className={styles.details}>
          <span>{bikeBrand}</span>
          <span>{bikeModel}</span>
          <span>{engineCapacity}</span>
        </div>
      </div>
    )
  }
  return (
    <>
      <div className={styles.accountDetails}>
        {isLoading && <Loader sz="lg" />}
        <div className={styles.accountDeactivateWrapper}>
          <div className={styles.accountDeactivate}>
            {userDetails && (
              <Button
                variant="deactivate"
                icon={userDetails.result[0].status ? CancelIcon : ActivateIcon}
                iconPosition="left"
                onClick={() => {
                  setDeactivateModalOpen(true)
                }}
              >
                {userDetails.result[0].status
                  ? "このアカウントの利用を再開する"
                  : "このアカウントを停止する"}
              </Button>
            )}
          </div>
        </div>
        {userDetails && (
          <div className={styles.card}>
            <div className={styles.profileImage}>
              <img
                src={`https://d2r3f355job4cx.cloudfront.net/${userDetails.result[0].profileImg}`}
                alt="Profile"
                style={{ height: "120px", width: "120px", borderRadius: "50%" }}
              />
            </div>
            <div className={styles.item}>
              <span className={styles.itemLabel}>特許庁</span>
              <span className={styles.itemValue}>
                {userDetails.result[0].nickName}
              </span>
            </div>
            <div className={styles.item}>
              <span className={styles.itemLabel}>メールアドレス</span>
              <span className={styles.itemValue}>
                {userDetails.result[0].email}
              </span>
            </div>
            <div className={styles.item}>
              <span className={styles.itemLabel}>都道府県</span>
              <span className={styles.itemValue}>
                {userDetails.result[0].province}
              </span>
            </div>
            <div className={styles.item}>
              <span className={styles.itemLabel}>性別</span>
              <span className={styles.itemValue}>
                {userDetails.result[0].gender === "Male" ? "男性" : "女性"}
              </span>
            </div>
            <div className={styles.item}>
              <span className={styles.itemLabel}>フォロー数</span>
              <span className={styles.itemValue}>
                {userDetails.result[0].followingCount}
              </span>
            </div>
            <div className={styles.item}>
              <span className={styles.itemLabel}>フォロワー数</span>
              <span className={styles.itemValue}>
                {userDetails.result[0].followersCount}
              </span>
            </div>
            <div className={styles.item}>
              <span className={styles.itemLabel}>最終ログイン</span>
              <span className={styles.itemValue}>
                {userDetails.result[0].lastLogin
                  ? "-"
                  : userDetails.result[0].lastLogin}
              </span>
            </div>
            <div className={styles.item}>
              <span className={styles.itemLabel}>ステータス</span>
              <span className={styles.itemValue}>
                {userDetails.result[0].status === "active"
                  ? "利用停止中"
                  : "利用中"}
              </span>
            </div>

            <div className={styles.bikesList}>
              <span className={styles.itemLabel}>Myバイク</span>
              {userDetails.result[0].bikeDetails?.map((bike) => (
                <BikeDetail
                  imageUrl={bike.bikeImg}
                  bikeBrand={bike.bikeName}
                  bikeModel={bike.modelName}
                  engineCapacity={bike.emission}
                  key={bike._id}
                />
              ))}
            </div>
            <DeactivateUserModal
              isOpen={isDeactivateModalOpen}
              onClose={() => setDeactivateModalOpen(false)}
              userId={userDetails.result[0].userId}
              name={userDetails.result[0].nickName}
              title={
                userDetails.result[0].status
                  ? "このアカウントを停止しますか？"
                  : "このアカウントの利用を再開しますか？"
              }
              fetchUserDetails={fetchUserDetails}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default AccountDetails
