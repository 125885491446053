import useSWR from "swr"
import { useAPI } from "./useAPI"

interface GraphDataResponse {
  success: true
  result: BarGraphData[]
}

const useTalkRoomGraphData = ({
  startDate,
  endDate,
}: {
  startDate: string
  endDate: string
}) => {
  const { get } = useAPI()
  const {
    data: talkRoomGraphDetail,
    error,
    mutate: fetchTouringGraphDetails,
    isLoading,
  } = useSWR<GraphDataResponse>(
    `/admin/category/postCount?startDate=${startDate}&endDate=${endDate}&postType=talkRoom`,
    (url: string) => get(url),
    {
      revalidateOnFocus: false,
    }
  )

  return {
    talkRoomGraphDetail,
    fetchTouringGraphDetails,
    isLoading,
    error,
  }
}

export default useTalkRoomGraphData
