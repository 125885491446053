import Button from "common/components/atoms/Button"
import Card from "common/components/atoms/Card"
import Title from "common/components/atoms/Title"
import Text from "common/components/atoms/Text"
import React, { useState } from "react"
import Modal from "common/components/atoms/Modal"
import Input from "common/components/atoms/Input"
import useAddBike from "common/hooks/useAddBike"
import Loader from "common/components/atoms/Loader"
import useError from "common/hooks/useErrors"
import { getErrorMessage } from "utils/helper"

const ConfirmAddBikeModal = ({
  isOpen,
  onClose,
  bikeModel,
  closeEditmodal,
}: {
  isOpen: boolean
  onClose: () => void
  bikeModel: AddBikePayload
  closeEditmodal: () => void
}) => {
  const { onAddBike, isLoading } = useAddBike()
  const setErrorNotification = useError((state) => state.setError)
  const [error, setError] = useState("")

  const handleSubmit = () => {
    if (!bikeModel.name || !bikeModel.brand || !bikeModel.emission) {
      setError("All fields are required")
      return
    }
    setError("")
    onAddBike(bikeModel)
      .then((res) => {
        if (res.success) {
          onClose()
          closeEditmodal()
        }
        setErrorNotification("Bike added successfully", "success")
      })
      .catch((err) => {
        setErrorNotification(getErrorMessage(err.subStatusCode))
        onClose()
      })
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Card>
        <Title order={6} fw="bold">
          この内容で追加登録しますか？
        </Title>
        <div
          style={{
            width: "100%",
            padding: "2rem",
            gap: "24px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ width: "100%" }}>
            <Text ta="left" sz="xxs">
              バイク名{" "}
            </Text>
            <Text ta="left" sz="sm">
              {bikeModel.name}
            </Text>
          </div>
          <div style={{ width: "100%" }}>
            <Text ta="left" sz="xxs">
              メーカー
            </Text>
            <Text ta="left" sz="sm">
              {bikeModel.brand}
            </Text>
          </div>
          <div style={{ width: "100%" }}>
            <Text ta="left" sz="xxs">
              排気量
            </Text>
            <Text ta="left" sz="sm">
              {bikeModel.emission}
            </Text>
          </div>
          <div style={{ width: "100%" }}>
            <Button onClick={handleSubmit} disabled={isLoading}>
              追加登録する
            </Button>
            <Button variant="grey" onClick={onClose}>
              キャンセル
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  )
}

export default ConfirmAddBikeModal
