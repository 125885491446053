import React, { useEffect } from "react"
import FormLayout from "common/components/templates/FormLayout"
import Title from "common/components/atoms/Title"
import Input from "common/components/atoms/Input"
import Button from "common/components/atoms/Button"
import Link from "common/components/atoms/Link"
import useError from "common/hooks/useErrors"
import useAuthStore from "store/authStore"
import useLogin from "common/hooks/useLogin"
import { setCookies } from "utils/cookies"
import { emailRegex, passwordRegex } from "utils/constant"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { getErrorMessage } from "utils/helper"
import { useNavigate } from "react-router-dom"
import Loader from "common/components/atoms/Loader"
import Text from "common/components/atoms/Text"
import { useTranslation } from "react-i18next"

const Login = () => {
  const setIsAuthenticated = useAuthStore((state) => state.setIsAuthenticated)
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated)
  const setError = useError((state) => state.setError)
  const navigate = useNavigate()
  const userType = useAuthStore((state) => state.userType)
  const { onLogin, isLoading } = useLogin()
  const { t } = useTranslation()

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/user-list")
    }
  }, [isAuthenticated])

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email(getErrorMessage("1006"))
      .required(getErrorMessage("1005"))
      .matches(emailRegex, getErrorMessage("1006")),
    password: yup
      .string()
      .required(getErrorMessage("1008"))
      .matches(passwordRegex, getErrorMessage("1003")),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const handleLogin = (userdata: UserLogin) => {
    onLogin(userdata)
      .then((data: AuthTokens) => {
        if (data) {
          setCookies("accessToken", data.accessToken)
          setCookies("refreshToken", data.refreshToken)
          setIsAuthenticated(true)
          navigate("/dashboard")
        }
      })
      .catch((error: any) => {
        setError(getErrorMessage(error.subStatusCode))
      })
  }

  return (
    <FormLayout>
      <Title order={5} color="black" fw="bold">
        {userType === "admin" ? t("titles.adminLogin") : t("titles.login")}
      </Title>
      {userType === "influencer" && (
        <Text ta="center" color="red" sz="xs" fw="bold">
          {`※お申込み内容を弊社で確認させて頂いた後、\nログインいただけます。`}
        </Text>
      )}
      <div
        style={{
          width: "100%",
          padding: "2rem",
          gap: "24px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Input
          label={t("fields.email")}
          placeholder={t("placeholders.email")}
          id="email"
          inputProps={{ ...register("email") }}
          type="email"
          error={errors.email?.message}
        />
        <Input
          label={t("fields.password")}
          placeholder={t("placeholders.password")}
          id="password"
          inputProps={{ ...register("password") }}
          type="password"
          error={errors.password?.message}
        />
        <Link to="/forget-password">{t("common.forgotPassword")}</Link>
        <Button variant="black" onClick={handleSubmit(handleLogin)}>
          {isLoading && <Loader />} {t("buttons.login")}
        </Button>
      </div>
    </FormLayout>
  )
}

export default Login
