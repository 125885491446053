import React from "react"
import logo from "./logo.svg"
import { Router } from "routes"
import "./App.css"
import "../src/i18n/config"
import Alert from "common/components/molecules/Alert"

function App() {
  return (
    <div className="App">
      <Alert />
      <Router />
    </div>
  )
}

export default App
