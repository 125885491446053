import React from "react"
import FormLayout from "common/components/templates/FormLayout"
import Title from "common/components/atoms/Title"
import Text from "common/components/atoms/Text"
import { useTranslation } from "react-i18next"
import Button from "common/components/atoms/Button"

const PasswordSent = () => {
  const { t } = useTranslation()

  return (
    <FormLayout>
      <Title order={5} color="black" fw="bold">
        {t("titles.emailSent")}
      </Title>
      <Button variant="black">ログインページへ</Button>
    </FormLayout>
  )
}

export default PasswordSent
