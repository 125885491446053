import React from "react"
import FormLayout from "common/components/templates/FormLayout"
import Title from "common/components/atoms/Title"
import Input from "common/components/atoms/Input"
import Button from "common/components/atoms/Button"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { passwordRegex } from "utils/constant"
import { getErrorMessage } from "utils/helper"
import useResetPassword from "common/hooks/useResetPassword"
import useAuthStore from "store/authStore"
import { useNavigate, useParams } from "react-router-dom"
import useError from "common/hooks/useErrors"
import { useTranslation } from "react-i18next"
import Loader from "common/components/atoms/Loader"
import otpStore from "store/otpStore"

const ResetPassword = () => {
  const { onResetPassword, isLoading } = useResetPassword()
  const params = useParams()
  const navigate = useNavigate()
  const setPasswordError = useError((state) => state.setError)
  const setLoading = useAuthStore((state) => state.setLoading)
  const { t } = useTranslation()
  const otpId = otpStore((state) => state.otpId)

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required(getErrorMessage("1008"))
      .matches(passwordRegex, getErrorMessage("1003")),
    confirmPassword: yup
      .string()
      .required(getErrorMessage("1008"))
      .oneOf([yup.ref("password")], getErrorMessage("1009")),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const handleResetPassword = (passwordData: resetPasswordProps) => {
    if (otpId) {
      setLoading(true)
      onResetPassword({ otpId: otpId, password: passwordData.password })
        .then((response) => {
          if (response.success) {
            setPasswordError("パスワードが変更されました", "success")
            navigate("/login")
          }
        })
        .catch((error: any) =>
          setPasswordError(getErrorMessage(error.subStatusCode))
        )
    }
  }

  return (
    <FormLayout>
      <Title order={5} color="black" fw="bold">
        {t("titles.resetPassword")}
      </Title>
      <div
        style={{
          width: "100%",
          padding: "2rem",
          gap: "24px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Input
          label={t("fields.password")}
          placeholder={t("placeholders.password")}
          id={"password"}
          inputProps={{ ...register("password") }}
          type={"password"}
          error={errors.password?.message}
        />
        <Input
          label={t("fields.confirmPassword")}
          placeholder={t("placeholders.confirmPassword")}
          id={"confirmPassword"}
          inputProps={{ ...register("confirmPassword") }}
          type={"password"}
          error={errors.confirmPassword?.message}
        />
        <Button variant="black" onClick={handleSubmit(handleResetPassword)}>
          {isLoading && <Loader />}パスワード変更
        </Button>
      </div>
    </FormLayout>
  )
}

export default ResetPassword
