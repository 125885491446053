import React from "react"
import styles from "./SideBar.module.scss"
import { useLocation } from "react-router-dom"

const SideBar = () => {
  const location = useLocation()

  return (
    <aside className={styles.aside}>
      <header>
        <h2>MotoFellow</h2>
        <h3>管理者用ページ</h3>
      </header>
      <menu>
        <a href="/user-list" style={{ textDecoration: "none", color: "white" }}>
          <li
            className={`${styles.linkItem} ${location.pathname.includes("/user-list") && styles.active}`}
          >
            ユーザーリスト
          </li>
        </a>
        <a href="/bike-list" style={{ textDecoration: "none", color: "white" }}>
          <li
            className={`${styles.linkItem} ${location.pathname.includes("/bike-list") && styles.active}`}
          >
            バイクリスト
          </li>
        </a>
        <a
          href="/insight"
          style={{ textDecoration: "none", color: "white" }}
        >
          <li
            className={`${styles.linkItem} ${location.pathname.includes("/insight") && styles.active}`}
          >
            カテゴリリスト
          </li>
        </a>
      </menu>
    </aside>
  )
}

export default SideBar
