import React from "react"
import Button from "common/components/atoms/Button"
import Card from "common/components/atoms/Card"
import Input from "common/components/atoms/Input"
import Title from "common/components/atoms/Title"
import Modal from "common/components/atoms/Modal"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { passwordRegex } from "utils/constant"
import { getErrorMessage } from "utils/helper"
import FormLayout from "common/components/templates/FormLayout"
import useChangePassword from "common/hooks/useChangePassword"
import useError from "common/hooks/useErrors"
import Loader from "common/components/atoms/Loader"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

const ChangePasswordModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const { t } = useTranslation()
  const validationSchema = yup.object().shape({
    currentPassword: yup
      .string()
      .required(getErrorMessage("1008"))
      .matches(passwordRegex, getErrorMessage("1003")),
    newPassword: yup
      .string()
      .required(getErrorMessage("1008"))
      .matches(passwordRegex, getErrorMessage("1003")),
    confirmPassword: yup
      .string()
      .required(t(getErrorMessage("3001")))
      .oneOf([yup.ref("newPassword")], t(getErrorMessage("3006"))),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const { onChangePassword, isLoading } = useChangePassword()
  const setError = useError((state) => state.setError)
  const navigate = useNavigate()

  const handlePasswordChange = (data: ChangePasswordProps) => {
    onChangePassword(data)
      .then((response) => {
        setError("パスワードが変更されました", "success")
        navigate("/")
        onClose()
      })
      .catch((err: ResponseError) =>
        setError(getErrorMessage(err?.subStatusCode))
      )
  }
  console.log(errors)
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Card>
        <Title order={5} color="black" fw="bold">
          {t("titles.changePassword")}
        </Title>
        <div
          style={{
            width: "100%",
            padding: "2rem",
            gap: "24px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Input
            label={t("fields.currentPassword")}
            placeholder={t("placeholders.currentPassword")}
            id="currentPassword"
            inputProps={{ ...register("currentPassword") }}
            type="password"
            error={errors.currentPassword?.message}
          />
          <Input
            label={t("fields.newPassword")}
            placeholder={t("placeholders.newPassword")}
            id="newPassword"
            inputProps={{ ...register("newPassword") }}
            type="password"
            error={errors.newPassword?.message}
          />
          <Input
            label={t("fields.confirmPassword")}
            placeholder={t("placeholders.confirmPassword")}
            id="confirmPassword"
            inputProps={{ ...register("confirmPassword") }}
            type="password"
            error={errors.confirmPassword?.message}
          />
          <Button onClick={handleSubmit(handlePasswordChange)}>
            {isLoading && <Loader />}
            {t("buttons.edit")}
          </Button>
          <Button variant="grey" onClick={onClose}>
            {t("buttons.cancel")}
          </Button>
        </div>
      </Card>
    </Modal>
  )
}

export default ChangePasswordModal
