import React, { useState } from "react"
import styles from "./Navbar.module.scss"
import { ReactComponent as DropdownArrow } from "../../../assets/icons/dropdown.svg"
import { ReactComponent as RightArrow } from "../../../assets/icons/right-chevron.svg"

import { ReactComponent as MobileDropdownArrow } from "../../../assets/icons/dropdown-light.svg"
import { removeCookies } from "utils/cookies"
import useAuthStore from "store/authStore"
import { useAPI } from "common/hooks/useAPI"
import { getBreadcrumbs } from "utils/helper"

import { useNavigate } from "react-router-dom"
import ChangePasswordModal from "common/components/templates/ChangePasswordModal/ChangePasswordModal"
import EditAccountModal from "common/components/templates/EditAccountModal/EditAccountModal"
import Breadcrumb from "../Breadcrumb"
import useAdmin from "common/hooks/useAdmin"

const Navbar = () => {
  const { logout, userType } = useAuthStore((state) => state)
  const [isChangePasswordModalOpen, setChangePasswordModalOpen] =
    useState(false)
  const [isEditAccountModalOpen, setEditAccountModalOpen] = useState(false)
  const { put } = useAPI()
  const { adminDetails } = useAdmin()

  const navigate = useNavigate()

  const onLogout = () => {
    put(`/admin/auth/logout`).then(() => {
      removeCookies("accessToken")
      removeCookies("refreshToken")
      logout()
      navigate("/login")
    })
  }
  const breadCrumbData = getBreadcrumbs()

  return (
    <nav className={styles.nav}>
      <Breadcrumb items={breadCrumbData} separator={<RightArrow />} />
      <menu className={styles.menu}>
        {adminDetails && adminDetails.result}
        <DropdownArrow />
      </menu>
      <menu className={styles.mobileMenu}>
        <div>
          <h3>{"menuTitle"}</h3>
          <MobileDropdownArrow />
        </div>
      </menu>
      <ul className={styles.dropdownMenu}>
        <li onClick={() => setEditAccountModalOpen(true)}>アカウントを編集</li>
        <li onClick={() => setChangePasswordModalOpen(true)}>
          パスワードを変更
        </li>
        <li onClick={onLogout}>ログアウト</li>
      </ul>
      <ChangePasswordModal
        isOpen={isChangePasswordModalOpen}
        onClose={() => setChangePasswordModalOpen(false)}
      />
      <EditAccountModal
        isOpen={isEditAccountModalOpen}
        onClose={() => setEditAccountModalOpen(false)}
      />
    </nav>
  )
}

export default Navbar
